<template>
	<div>
		<!-- -->
			<v-card height="150">
				<s-toolbar
						close
						dark
						save
						@close="closeDialogDecrease"
						@save="saveDecrease"
						color="light-blue darken-3"
						label="Mermas"
					></s-toolbar>
				<v-container>
					<v-row>
						<v-col cols="6" lg="6" md="6" sm="12" class="s-col-form">
							<s-text 
								label="Cantidad"
								v-model="decrease.DecAmount"
							></s-text>
						</v-col>
						<v-col cols="6" lg="6" md="6" sm="12" class="s-col-form">
							<s-select-definition
								label="Tipo Merma"
								:def="1320"
							></s-select-definition>
							<!-- <s-text 
								label="Tipo Merma"
								v-model="decrease.TypeDecrease"
							></s-text> -->
						</v-col>
					</v-row>
				</v-container>
			</v-card>
	</div>
</template>
 
<script>
import SSelectDefinition from '../../Utils/SSelectDefinition.vue'
	

	export default {
  components: { SSelectDefinition },
		data() {
			return {
				decrease: {}
				/* config: {
					model: {
						DecID: "ID"
					},
					service: service,
					headers: [
						{text: "ID", value:"DecID"}
					]
				} */
			}
		},

		methods: {
			closeDialogDecrease() {
				this.$emit("closeDialogDecrease")
			},

			saveDecrease()
			{
				this.$emit("saveDecrease", this.decrease)
			}
		},
	}
</script>