<template>
	<div>
		<reception-storage></reception-storage>
		<!-- <reception-fresh :title="title" :ship="ship"></reception-fresh> -->
	</div>
</template>

<script>
	import ReceptionStorage from '../../TechnicalAssistance/ProgrammingStorage/ReceptionStorage.vue'
	import Decrease from '@/components/FrozenProduction/Reception/decrease.vue';
	/* import ReceptionFresh from '@/views/FreshProduction/RawMaterialReception/ReceptionFresh.vue'; */

	export default {
		components: { 
			 Decrease,ReceptionStorage
		},

		data () {
			return {
				title: "Recepción Acopio",
				dialogDecrease: false,
				decrease: {},
				ship: 3
			}
		},
		
	}
</script>